.header {
    width: 100vw;
    height: 72px;
    background: var(--Button-Text-color, #FFF);
    padding: 14px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .icons {
        display: flex;
        gap: 1.25vw;
    }

    .menu {
        width: 43px;
        height: 43px;
        border-radius: 50%;
        background: #FFF;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 16px;
            height: 14px;
        }
    }

    .nav-bar-logo {
        height: 44px;
    }

    Input {
        width: 49.48vw;
        padding: 0.63vw 8px 0.63vw 26px;
        border-radius: 0.26vw;
        border: 1px solid #878787;
        background: var(--Button-Text-color, #FFF);
        color: var(--Seconday-color, #B3B9BD);
        font-family: Manrope;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140.04%;

        SearchOutlined {
            width: 1.25vw;
            height: 1.25vw;
        }
    }

    .drop-down {
        display: inline-flex;
        align-items: center;
        gap: 0.63vw;

        .profile {
            color: #000;
            font-family: 'Roboto';
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        .anticon {
            font-size: 12px;
        }
    }
}

.drop-down-item {
    display: flex;
    align-items: center;
    gap: 1.1vw;
    color: #000;
    font-family: 'Roboto';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.home-body {
    height: calc(100vh - 72px);
    background: #F5F5F5;
}