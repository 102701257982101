.forgot-password-page {
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
}

.forgot-logo-container {
    .logo-container-div {
        display: flex !important;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1.875vw;
    }

    .ivory-forgot-bg {
        height: 74vh;
        /* padding: 4.45vh 0 0; */
    }

    .ivory-description {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 44.584vw;
        /* height: 5.42vw; */

        .description-header {
            color: var(--Text-Color, #333);
            text-align: center;
            font-family: Manrope;
            font-size: 32px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .description-content {
            color: var(--Seconday-color, #B3B9BD);
            text-align: center;
            font-family: Manrope;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 123%;
            letter-spacing: 0.043px;
        }
    }

    .carousel-item {
        position: unset;
    }

    .carousel-caption {
        position: unset;
        padding-top: 0;
        padding-bottom: 3.5rem;
    }

    .carousel-indicators [data-coreui-target] {
        width: 1.35417vw;
        background-color: #AEAEAE;
        margin-bottom: 0;
    }

    .carousel-indicators .active {
        width: 1.35417vw;
        background-color: #006FE6;
    }
}

.form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5625vw;

    .ivory-logo-img {
        height: 4.838vh;
        flex-shrink: 0;
    }
}

.forgot-form-container {
    width: 30.52vw;
    padding: 3.125vw 2.96875vw;
    border-radius: 0.26vw;
    border: 1px solid var(--Seconday-color, #B3B9BD);
    background: var(--Button-Text-color, #FFF);
    display: flex;
    flex-direction: column;
    gap: 4vh;

    .form-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.63vw;
    }

    .forgot-password-text {
        color: var(--Text-Color, #333);
        font-family: Manrope;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .header-description {
        color: var(--Text-Color, #333);
        text-align: center;
        font-family: Manrope;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 148.59%;
    }

    .forgot-form {
        display: flex;
        flex-direction: column;
        gap: 3.7vh;
    }

    .forgot-form-field {
        display: flex;
        flex-direction: column;
        width: 24.5834vw;
        gap: 0.252vw;

        .email-text {
            color: #A3A3A3;
            font-family: Manrope;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        .login-form-input {
            display: inline-flex;
            padding: 0.7295vw 0.625vw;
            align-items: center;
            border-radius: 0.26vw;
            background: #F3F9FD;
            border: none;
            color: var(--Text-Color, #333);
            font-family: Manrope;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }

    .buttons-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 3.7vh;
    }

    .go-back {
        color: var(--Primary-color, #006FE6);
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        cursor: pointer;
    }

    .send-button {
        display: flex;
        width: 24.5834vw;
        padding: 1.459vw 1.3542vw;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 0.26vw;
        background: var(--Primary-color, #006FE6);
        color: var(--Button-Text-color, #FFF);
        font-family: Manrope;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}

@media screen and (max-width: 820px) {
    .ivory-forgot-bg {
        width: 93% !important;
        height: fit-content !important;
    }

    .ivory-description {
        width: 88% !important;
    }

    .form-container {
        padding: 12vh 0 10vh !important;
    }

    .forgot-form-container {
        width: 57% !important;
    }

    .forgot-form-field {
        width: 50vw !important;
    }

    .login-form-input {
        padding: 1.6vw !important;
    }

    .send-button {
        width: 50vw !important;
        padding: 2.8vw !important;
    }
}

@media screen and (max-width: 991px) {
    .forgot-logo-container {
        display: none;
    }

    .forgot-form-container {
        width: 55vw !important;
        border: none !important;
    }

    .forgot-form-field,
    .send-button {
        width: 50vw !important;
    }

    .login-form-input {
        padding: 1.4vw !important;
    }

    .send-button {
        padding: 2.5vw 1.3542vw !important;
    }
}

@media screen and (min-width: 1450px) and (max-width: 1750px) {
    .forgot-logo-container {
        .ivory-forgot-bg {
            height: 69vh !important;
        }
    }
}

@media screen and (min-width: 1200px) and (max-width: 1450px) {
    .forgot-logo-container {
        .ivory-forgot-bg {
            height: 66vh !important;
        }
    }

    .forgot-form-container {
        .forgot-password-text {
            font-size: 28px !important;
        }

        .header-description {
            font-size: 18px !important;
        }
    }

    .ivory-logo-img {
        height: 6vh !important;
    }
}

@media screen and (min-width: 2000px) {
    .forgot-logo-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (min-width: 2000px) and (min-height: 1200px) {
    .form-container {
        height: unset !important;
        padding: 0 3vw 0 0 !important;
        justify-content: center !important;
    }

    .forgot-form-container {
        width: 23vw !important;
        padding: 2vw !important;
    }

    .forgot-form-field,
    .send-button {
        width: 19vw !important;
    }

    .send-button {
        padding: 1.1vw !important;
    }
}

@media screen and (min-width: 992px) and (max-width: 1060px) {
    .ivory-forgot-bg {
        width: 95% !important;
        padding: 10vh 0 0 0 !important;
    }

    .form-container {
        padding: 9.99vh 0;
    }

    .forgot-form-container {
        width: 38vw !important;
        padding: 3.5vw 2vw !important;
    }

    .forgot-form-field {
        width: 34vw !important;
    }

    .send-button {
        width: 34vw !important;
        padding: 1.7vw !important;
    }
}

@media screen and (min-width: 1061px) and (max-width: 1199px) {
    .form-container {
        padding: 9.99vh 0;
    }

    .forgot-form-container {
        width: 35vw !important;
        padding: 3.5vw 2vw !important;
    }

    .forgot-form-field {
        width: 31vw !important;
    }

    .send-button {
        width: 31vw !important;
        padding: 1.6vw !important;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1320px) {

    .forgot-form-container {
        width: 32vw !important;
        padding: 2vw 2vw !important;

        .forgot-form-field,
        .send-button {
            width: 28vw;
        }
    }
}

@media screen and (min-width: 1321px) and (max-width: 1520px) {
    /* .ivory-forgot-bg {
        padding: 8.5vh 0 0 0 !important;
    } */

    .form-container {
        padding: 10vh 3.5vw 10vh 0;
    }

    .forgot-form-container {
        width: 33vw !important;
        padding: 2.5vw 2.5vw !important;

        .forgot-form-field,
        .send-button {
            width: 28vw;
        }
    }
}