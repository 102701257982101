.staff-container {
    width: 100%;
    height: calc(100% - 52px);
    padding: 0.5vw 1.5625vw 1.5625vw;

    .data-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        font-family: Manrope;

        .sync-time {
            display: flex;
            justify-content: end;
            position: relative;
            top: -32px;

            color: var(--Secondary-Text-Color, #757575);
            text-align: right;
            font-feature-settings: 'liga' off, 'clig' off;
            font-family: Manrope;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 15.247px;
            /* 108.904% */
            letter-spacing: 0.53px;
        }

        .ant-tabs-top>.ant-tabs-nav {
            margin: 0;
        }

        .ant-tabs .ant-tabs-tab,
        .ant-tabs .ant-tabs-tab+.ant-tabs-tab {
            margin: 0;
            padding: 0.99vw 0.834vw;
        }

        .ant-tabs-tab .ant-tabs-tab-active {
            color: #006FE6;
        }

        .ant-divider-horizontal {
            background: var(--Line, #d9d9d9);
            height: 1px;
            margin: 0 0 12px 0;
        }

        .ant-tabs-tab {
            color: var(--Secondary-Text-Color, #979797);
            font-feature-settings: "liga" off, "clig" off;
            font-family: Manrope;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0.53px;
        }

        .ant-tabs-tab-active {
            font-weight: 600 !important;
        }

        .ant-spin-spinning {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .data-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* font-family: Verdana, Geneva, Tahoma, sans-serif; */
        font-size: 13px;
        height: 56px;

        .all-clinic-text {
            color: #333;
            font-feature-settings: 'liga' off, 'clig' off;
            font-family: Manrope;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            /* 120% */
            letter-spacing: 0.53px;
        }

        .search-container {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1.5625vw;

            .sync-icon,
            .add-icon {
                width: 24px;
                height: 24px;
            }

            Button {
                color: #333;
                font-feature-settings: 'liga' off, 'clig' off;
                font-family: Manrope;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 15.247px;
                /* 95.291% */
                letter-spacing: 0.53px;
                padding: 0;
            }

            .ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover {
                background: none;
            }
        }

        .search-box {
            display: flex;
            width: 280px;
            height: 35px;
            padding: 5px 12px;
            align-items: center;
            border-radius: 5px;
            border: var(--Number, 1px) solid var(--Line, #D9D9D9);
            background: var(--White, #FFF);

            Input {
                overflow: hidden;
                color: #BABABA;
                font-feature-settings: 'liga' off, 'clig' off;
                text-overflow: ellipsis;
                font-family: Manrope;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 15.247px;
                letter-spacing: 0.53px;
                text-transform: none;
            }

            .anticon-search {
                width: var(--icon-width);
                height: var(--icon-height);
                color: #323232;
                justify-content: end;
            }
        }
    }

    .actions {
        display: flex;
        flex-direction: row;
        gap: 24px;

        .book-app-text {
            cursor: pointer;

            color: #006FE6;
            font-feature-settings: 'liga' off, 'clig' off;
            font-family: Manrope;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            text-decoration-line: underline;
            text-decoration-style: solid;
            text-decoration-skip-ink: none;
            text-decoration-thickness: auto;
            text-underline-offset: auto;
            text-underline-position: from-font;
            word-wrap: break-word;
        }
    }

    .more {
        width: 24px;
        height: 24px;
        cursor: pointer;
    }

    .ag-cell-wrapper,
    .ag-cell-value {
        width: 100%;
    }

    .name-container {
        display: flex !important;
        width: 100%;
        gap: 0.6vw;

        .initials {
            width: 42.52px;
            height: 42.52px;
            display: flex !important;
            justify-content: center;
            align-items: center;
            background: #D9D9D9;
            border-radius: 50%;
            color: #333;
            font-feature-settings: 'liga' off, 'clig' off;
            font-family: Manrope;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 15.247px;
            letter-spacing: 0.106px;
        }
    }

    .row-data-element {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        height: 100%;
        width: 100%;
        gap: 5px;
        font-feature-settings: "liga" off, "clig" off;
        font-family: Manrope;
        font-size: 16px;
        font-style: normal;
        line-height: 15.247px;
        letter-spacing: 0.53px;

        .clinic-name {
            color: var(--Primary-Color, #006FE6);
            font-weight: 500;
            letter-spacing: 0.085px;
            /* text-decoration-line: underline; */
        }

        .name-specialization {
            display: flex !important;
            flex-direction: column !important;
            width: calc(100% - 55px);
            gap: 0.417vw;
        }

        .full-name {
            max-width: 100%;
            color: #006FE6;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0.085px;
            text-transform: capitalize;
            text-decoration-line: underline;
            text-decoration-style: solid;
            text-decoration-skip-ink: none;
            text-decoration-thickness: auto;
            text-underline-offset: auto;
            text-underline-position: from-font;
        }

        .email {
            max-width: 100%;
            color: var(--Primary-Color, #006FE6);
            font-weight: 400;
            line-height: 22px;
            text-decoration-line: underline;
            cursor: pointer;
        }

        .phone {
            color: var(--Primary-Color, #006FE6);
            font-weight: 400;
        }

        .status {
            display: flex;
            padding: 7px 10px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 5px;
            text-align: center;
            color: #333;
            font-weight: 400;
            text-transform: uppercase;
        }

        .status-pending {
            border: 1px solid #ffb429;
            background: #ffdd9c;
        }

        .status-active {
            border: 1px solid var(--Status-Stroke, #97ff5b);
            background: var(--Status-Color, #dafec5);
        }

        .expired {
            color: #ff3e3e;
            line-height: 22px;
        }

        .login-pending {
            color: orange;
            line-height: 22px;
        }

        .plan {
            color: var(--Primary-Color, #006FE6);
        }
    }

    .ellipsis-cell {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
    }

    .ag-theme-quartz {
        --ag-borders: none;
        --ag-active-color: none;
        --ag-range-selection-border-color: #fff;

        .ag-column-last {
            justify-content: end;

            .ag-cell-wrapper {
                width: unset;
            }

            .ag-header-cell-label {
                justify-content: end;
            }
        }

        .ag-ltr .ag-cell {
            border-right-width: 1px;
            display: flex;
        }
    }

    .ag-header-cell-sortable .ag-header-cell-label,
    .ag-header-cell-text {
        color: rgba(135, 135, 135, 0.85);
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: var(--font-family);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0.53px;
        text-transform: uppercase;
    }

    .custom-no-rows {
        font-size: 18px;
        font-family: 'Manrope';
        font-weight: 400;
    }
}

.staff-offcanvas {
    width: 840px !important;
}