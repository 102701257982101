.appointment-off-canvas {
    width: 420px !important;
    padding: 28px;
    display: flex;
    gap: 1.0417vw;

    ::-webkit-scrollbar {
        width: 8px;
        height: 4px;
        background: #D9D9D9;
    }

    ::-webkit-scrollbar-thumb {
        background: #979797;
        border-radius: 4px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #adadad;
    }

    .offcanvas-header {
        padding: 0;
        display: flex;
        flex-direction: column-reverse;
        gap: 1.0417vw;

        .offcanvas-title {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;

            color: var(--Text-Color, #333);
            font-family: var(--font-family);
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        .btn-close {
            position: relative;
            left: 48%;
        }
    }

    .offcanvas-body {
        padding: 0;

        .ant-spin-nested-loading,
        .ant-spin-nested-loading .ant-spin-container {
            height: 100%;
        }
    }

    .offcanvas-form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        gap: 1.0417vw;

        .fields-container {
            display: flex;
            flex-direction: column;
            gap: 1.25vw;
            height: 100%;
            overflow: auto;

            .ant-form-item {
                width: 100%;
            }
        }

        .form-row {
            display: flex;
            flex-direction: column;
            gap: 8px;

            .input-header {
                color: var(--Seconday-color, #B3B9BD);
                font-family: Manrope;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                display: flex;
                gap: 4px;
            }

            .ant-select-selector {
                padding: 0 !important;
                text-transform: capitalize;
            }

            .form-input {
                display: flex;
                width: 100%;
                height: 46px;
                padding: 12px var(--font-size-h3, 16px);
                align-items: center;
                align-self: stretch;
                border-radius: 3px;
                border: 1px solid #DADADA;
                /* background: #FFF; */

                overflow: hidden;
                color: var(--Text-Color, #333);
                text-overflow: ellipsis;
                font-family: Manrope;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;

                .calendar-icon {
                    width: 24px;
                    height: 24px;
                }
            }

            .ant-select-selection-placeholder {
                color: var(--Secondary-Text-Color, #757575);
                text-overflow: ellipsis;
                font-family: Manrope;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
                overflow: hidden;
                color: var(--Text-Color, #333);
                text-overflow: ellipsis;
                font-family: Manrope;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }

            .form-control {
                display: flex;
                width: 100%;
                height: 46px;
                align-items: center;
                align-self: stretch;
                border-radius: 3px;
                border: 1px solid #DADADA;
                background: #FFF;

                color: var(--Text-Color, #333);
                font-family: var(--font-family);
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }

        }

        .ant-form-item .ant-form-item-control-input-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .ant-picker .ant-picker-input>input,
        .ant-input-affix-wrapper >input.ant-input {
            color: var(--Text-Color, #333);
            text-overflow: ellipsis;
            font-family: Manrope;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        .timings {
            display: flex !important;
            align-items: center;
            /* justify-content: space-between; */
            gap: 8px;

            .ant-picker-outlined {
                display: inline-flex;
                width: 165px;
                height: 46px;
                padding: 12px;
                align-items: center;
                gap: 11px;
                flex-shrink: 0;
                border-radius: 3px;
                border: 1px solid #DADADA;
                background: #FFF;
            }

            .ant-picker .ant-picker-input>input {
                color: var(--Primary-Text-Color, #333);
                font-family: Manrope;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }

            span {
                color: var(--Seconday-color, #B3B9BD);
                font-family: Manrope;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            .duration {
                width: 165px;
                height: 46px;
                padding: 12px;
                align-items: center;
            }
        }

        .buttons-container {
            display: flex;
            justify-content: end;
            gap: 1.0417vw;
            padding: 0;

            .cancel-button {
                display: flex;
                min-width: 100px;
                height: unset;
                padding: 12px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                border-radius: 5px;
                border: 1px solid #006FE6;
                background: #FFF;

                color: #006FE6;
                text-align: center;
                font-family: Manrope;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 22px;
                /* 137.5% */
            }

            .add-button {
                display: flex;
                min-width: 100px;
                height: unset;
                padding: 12px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                border-radius: 5px;
                background: #006FE6;

                color: #FFF;
                text-align: center;
                font-family: Manrope;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 22px;
            }
        }
    }
}