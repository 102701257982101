.appointment-details-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 52px);
    padding: 1.5625vw 0 0;

    .appointment-details-view {
        height: 100%;
        width: 100%;

        .appointment-details-header {
            display: flex;
            gap: 1.25vw;

            padding: 0 1.5625vw 1.5625vw;
            border-bottom: 1px solid var(--Line, #BABABA);

            color: var(--Header-Text, #646464);
            font-feature-settings: 'liga' off, 'clig' off;
            font-family: Manrope;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0.53px;

            .back-icon,
            .edit-icon {
                width: 24px;
                height: 24px;
                cursor: pointer;
            }

            .appointment-info {
                display: flex !important;
                flex-direction: column !important;
                gap: 1.0417vw;
                width: 100%;
            }

            .appointment-basic-info {
                display: flex !important;
                flex-direction: column !important;
                gap: 0.417vw;
            }


            .appointment-id {
                display: flex;
                padding: 0.2vw 0.3125vw;
                justify-content: center;
                align-items: center;
                gap: 0.52vw;
                border-radius: 4px;
                background: var(--Selection-BG, #F6F6F6);

                font-family: Manrope;
            }

            .appointment-desc {
                display: flex;
                gap: 0.2vw;

                color: #333;
                font-feature-settings: 'liga' off, 'clig' off;
                font-family: Manrope;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                letter-spacing: 0.53px;

            }

            .appointment-title {
                font-weight: 600;
            }

            .appointment-date-time {
                display: flex;
                gap: 0.20vw;

                color: #878787;
                font-family: Manrope;
                text-transform: uppercase;
            }

            .appointment-personal-info {
                display: flex;
                align-items: flex-start;
                gap: 1.0417vw;
                width: 100%;
                justify-content: space-between;

                .appointment-info-col {
                    display: flex !important;
                    flex-direction: column !important;
                    align-items: flex-start;
                    gap: 0.417vw;
                    flex: 1 0 0;

                    color: #333;
                    font-feature-settings: 'liga' off, 'clig' off;
                    font-family: Manrope;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                    letter-spacing: 0.085px;

                    .label {
                        color: #878787;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 15.247px;
                        letter-spacing: 0.53px;
                        text-transform: uppercase;
                    }

                    .doctor-details {
                        display: flex !important;
                        flex-direction: column !important;

                        font-family: Manrope;
                        font-size: 16px;
                        font-weight: 400;
                        text-transform: capitalize;

                        .doctor-name {
                            color: var(--Primary-Color, #006FE6);
                        }
                    }

                    .address {}

                    .status {
                        padding: 0.25vw 0.52vw;
                        border-radius: 5px;
                        border: 1px solid var(--Status-Stroke, #97FF5B);
                        background: var(--Status-Color, #DAFEC5);
                    }
                }
            }
        }

        .appointment-details-body {
            padding: 1.5625vw 1.5625vw 1.25vw 4vw;

            display: flex !important;
            flex-direction: column !important;
            gap: 1.25vw;

            .medical-record-text {
                color: var(--Primary-Text-Color, #333);
                font-feature-settings: 'liga' off, 'clig' off;
                font-family: Manrope;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 15.247px;
                letter-spacing: 0.53px;
            }

            .appointment-details-row {
                gap: 1.0417vw;
            }

            .appointment-info-col {
                display: flex !important;
                flex-direction: column !important;
                align-items: flex-start;
                gap: 0.417vw;
                flex: 1 0 0;

                color: #333;
                font-feature-settings: 'liga' off, 'clig' off;
                font-family: Manrope;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                letter-spacing: 0.085px;

                .label {
                    color: #878787;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 15.247px;
                    letter-spacing: 0.53px;
                    text-transform: uppercase;
                }

                ul {
                    padding-inline-start: 1.5625vw !important;
                }
            }
        }

    }
}