.signup-main-div {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

.signup-slider-container {
  .ivory-description {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .carousel-item {
    position: unset;
  }

  .carousel-caption {
    position: unset;
    padding-top: 0;
    padding-bottom: 3.5rem;
  }

  .carousel-indicators [data-coreui-target] {
    width: 1.35417vw;
    background-color: #aeaeae;
    margin-bottom: 0;
  }

  .carousel-indicators .active {
    width: 1.35417vw;
    background-color: #006fe6;
  }
}

.signup-form-col {
  .ant-carousel .slick-dots li {
    width: 1.35417vw;
    background: #aeaeae;
  }

  .ant-carousel .slick-dots li.slick-active button {
    width: 1.35417vw;
    background: #006fe6;
  }

  .ant-carousel .slick-dots-bottom {
    bottom: -3vh !important;
  }
}

@media screen and (max-width: 1440px) {
  .signup-slider-col {
    display: none;
  }

  .logo-container {
    display: none !important;
  }

  .signup-form-slider {
    padding: 0 0 50px 0;
    /* display: none; */
  }

  .signup-slider-container {
    display: none;
  }
}

@media screen and (min-width: 1441px) {
  .signup-form-slider {
    display: none;
  }
}

.signup-form-slider {
  .ivorylogo {
    margin: 0px 80px 0 70px;
  }
}

@media screen and (max-width: 1441px) {
  .signup-form-col {
    padding: 50px 0;
  }

  .justify-center {
    justify-content: center;
  }
}

@media screen and (min-width: 900px) {
  .signup-form {
    width: 100%;
  }
}

.logo-container {
  display: flex;
  justify-content: center;
  width: 30.52vw;
}

.silderimage {
  /* width: 40.208333333333336vw; */
  /* height: 70.18518518518519vh; */
  height: 650px;
  margin-top: 2.0834vw;
}

.setpassword-silderimage {
  /* width: 40.208333333333336vw; */
  /* height: 57.407407407407405vh; */
  width: 57.604166666666664vw;
}

.signup-form {
  width: 30.52vw;
  border-radius: 5px;
  padding: 1.67vw 2.96875vw;
  /* padding: 1.5vh 57px 2.962962962962963vh 57px; */
  border: 1px solid var(--Seconday-color, #b3b9bd);
  background: var(--Button-Text-color, #fff);
  margin-bottom: 1.5625vw;
}

.clinic-registration-text {
  color: var(--Text-Color, #333);
  text-align: center;
  font-family: Manrope;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 1.67vw;
}

.ivorylogo {
  margin: 1.5625vw 0 1.0417vw;
  /* width: 150px; */
  height: 4.837685185185185vh;
  flex-shrink: 0;
}

.slider-header-text {
  color: var(--Text-Color, #333);
  text-align: center;
  font-family: Manrope;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 1.875vw 0 5px 0;
}

.slider-subheader-text {
  color: var(--Seconday-color, #b3b9bd);
  text-align: center;
  font-family: Manrope;
  font-size: 24px;
  font-style: normal;
  width: 44.5834vw;
  font-weight: 400;
  line-height: 150%;
  /* 24px */
  letter-spacing: 0.029px;
}

.input-header-text {
  /* color: #a3a3a3;
  font-size: 18px;
  font-weight: 500; */
  color: #a3a3a3;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0.26vw;
}

.signup-input-fields {
  /* margin: 0 0 2.037037037037037vh 0; */
  border-radius: 5px;
  background: #f3f9fd;
  /* height: 4.814814814814815vh;
   */
  height: 52px;
  border: 1px solid var(--Seconday-color, #b3b9bd);
  color: var(--Text-Color, #333);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-family: Manrope;
}

.signup-phone-field-row {
  display: flex;
  flex-direction: column;

  .form-control {
    display: flex;
    width: 100% !important;
    height: 52px;
    align-items: center;
    align-self: stretch;
    border-radius: 3px;
    border: 1px solid #dadada;
    background: #f3f9fd;

    color: var(--Text-Color, #333);
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.react-tel-input .special-label {
  display: none;
  white-space: nowrap;
}

.react-tel-input .invalid-number-message,
.react-tel-input .special-label {
  background: #fff;
  font-size: 13px;
  left: 25px;
  padding: 0 5px;
  position: absolute;
  top: -7px;
  z-index: 1;
}

.react-tel-input .selected-flag .arrow {
  margin-top: -3px !important;
  width: 14px !important;
  height: 9px !important;
  border: none !important;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='9' viewBox='0 0 14 9' fill='none'%3E%3Cpath d='M2 2L7.25 8L12.5 2' stroke='%23333333' stroke-width='2' stroke-linecap='square' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.react-tel-input .selected-flag:focus:before,
.react-tel-input .selected-flag.open:before {
  border: unset !important;
  box-shadow: none !important;
}

.react-tel-input .form-control:focus {
  box-shadow: none !important;
}

.react-tel-input .country-list .country-name {
  margin-right: 0 !important;
}

.react-tel-input .country-list .country {
  position: relative;
  padding: 12px 9px 13px 46px;
  color: var(--Primary-Text-Color, #333);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  gap: 0;

  .dial-code {
    color: #333 !important;
    font-family: Manrope !important;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: normal;
  }

  .dial-code::before {
    content: "(";
  }

  .dial-code::after {
    content: ")";
  }
}

.react-tel-input .country-list .country.highlight {
  border-radius: 5px;
  background: #006fe6 !important;
  color: var(--White, #fff);

  .dial-code {
    color: var(--White, #fff) !important;
  }
}

/* .signup-input-fields */
.signup-main-div
  :where(
    .css-dev-only-do-not-override-1gwfwyx
  ).ant-input-borderless.ant-input-status-error,
:where(
    .css-dev-only-do-not-override-1gwfwyx
  ).ant-input-borderless.ant-input-status-error
  input,
:where(
    .css-dev-only-do-not-override-1gwfwyx
  ).ant-input-borderless.ant-input-status-error
  textarea {
  color: var(--Text-Color, #333) !important;
}

.signup-main-div
  :where(.css-1gwfwyx).ant-input-borderless.ant-input-status-error,
:where(.css-1gwfwyx).ant-input-borderless.ant-input-status-error input,
:where(.css-1gwfwyx).ant-input-borderless.ant-input-status-error textarea {
  color: var(--Text-Color, #333) !important;
}

.signup-phone-field {
  /* margin: 0 0 2.037037037037037vh 0; */
  /* height: 4.814814814814815vh; */
  height: 52px;
  border-radius: 5px;
  border: 1px solid var(--Seconday-color, #b3b9bd);
  background: var(--Button-Text-color, #fff);
  display: flex;
  align-items: center;
  color: var(--Seconday-color, #333);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* Target the input field when its parent form item has an error */
.signup-main-div .ant-form-item-has-error .ant-input {
  background-color: #fef6f6;
  /* Light red background */
  border-color: #ec7063;
}

.terms-checkbox-text {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  /* line-height: 10px; */
  font-family: Manrope;
  padding: 0 12px;
  /* margin: 1.0185185185185186vh 0 1.8518518518518519vh 0; */
}

.terms-text {
  color: var(--Primary-color, #006fe6);
  font-size: 16px;
  font-family: Manrope;
  font-weight: 500;
  cursor: pointer;
}

.ant-checkbox-inner {
  width: 24px !important;
  height: 24px !important;
}

.ant-checkbox-inner::after {
  width: 0.417vw !important;
  height: 0.73vw !important;
  top: 45%;
  left: 20.5%;
}

.button-container {
  margin: 1.1vw 0;
}

.register-button {
  border-radius: 5px;
  background: var(--Primary-color, #006fe6);
  display: flex;
  width: 100%;
  height: 58px;
  color: var(--Button-Text-color, #fff);
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
}

.setpassword-button {
  border-radius: 5px;
  background: var(--Primary-color, #006fe6);
  display: flex;
  width: 100%;
  height: 3vw;
  font-family: Manrope;
  color: var(--Button-Text-color, #fff);
  font-size: 16px;
  font-weight: 600;
  margin: 5.277777777777778vh 0 0 0;
}

.go-back-button {
  border-radius: 5px;
  background: var(--Primary-color, #006fe6);
  height: 38px;
  color: var(--Button-Text-color, #fff);
}

.login-text-main {
  color: #000;
  /* font-family: Roboto; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  font-family: Manrope;
  line-height: normal;
}

.login-text {
  color: var(--Primary-color, #006fe6);
  /* font-family: Roboto; */
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.signup-main-div .ant-form-item {
  margin: 0 0 20px 0;
}

.resend-main-div {
  border-radius: 3px;
  background: var(--Button-Text-color, #fff);
  box-shadow: 3px 3px 27px 0px rgba(0, 0, 0, 0.05);
  width: 888px;
  /* height: 619px; */
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */
}

.check-your-inbox {
  color: var(--Text-Color, #333);
  font-family: Manrope;
  /* font-family: Roboto; */
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.click-on-link {
  margin: 30px 0 42px 0;
  font-family: Manrope;
  width: 585px;
  color: #000;
  text-align: center;
  /* font-family: Roboto; */
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.click-on-link-email {
  color: #000;
  font-family: Manrope;
  /* font-family: Roboto; */
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.no-email-text {
  font-family: Manrope;
  margin: 0 0 30px 0;
  color: var(--Text-Color, #333);
  /* font-family: Roboto; */
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.resend-btn {
  border-radius: 5px;
  background: var(--Primary-color, #006fe6);
  display: flex;
  width: 222px;
  height: 58px;
  padding: 12px 26px;
  justify-content: center;
  font-family: Manrope;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  color: var(--Button-Text-color, #fff);
  /* font-family: Roboto; */
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.wrong-address-text {
  color: var(--Seconday-color, #b3b9bd);
  /* font-family: Roboto; */
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: Manrope;
  text-align: center;
}

.log-out-text {
  color: var(--Primary-color, #006fe6);
  /* font-family: Roboto; */
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.resendmail {
  width: 213px;
}

.try-resend-text {
  color: var(--Text-Color, #333);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: Manrope;
  margin: 12px 0 0 0;
}

.countdown {
  color: var(--Tertiary-color, #ec7063);
  /* font-family: Roboto; */
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-family: Manrope;
}

.passowrd-text-one {
  color: var(--Text-Color, #333);
  text-align: center;
  /* font-family: Roboto; */
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
}

.passowrd-text-two {
  width: 373px;
  color: var(--Text-Color, #333);
  text-align: center;
  /* font-family: Roboto; */
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}

.password-btn {
  display: flex;
  /* max-width: 472px; */
  height: 58px;
  padding: 12px 26px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 5px;
  background: var(--Primary-color, #006fe6);
  color: #fff;
  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 50px 0 0 0;
}

.set-password-main-div {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;

  .ant-form-item {
    margin-bottom: 8px;
  }
}

.set-password-form-container {
  gap: 1.5625vw;

  .ivory-logo {
    height: 4.838vh;
  }

  .set-password-form {
    width: 30.52vw;
    border-radius: 5px;
    padding: 3.125vw 2.96875vw;
    border: 1px solid var(--Seconday-color, #b3b9bd);
    background: var(--Button-Text-color, #fff);
  }

  .set-password-text {
    color: var(--Text-Color, #333);
    font-family: Manrope;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 1.0417vw;
  }

  @media screen and (max-width: 1200px) {
    .set-password-form {
      width: 586px;
      height: 659px;
      border-radius: 5px;
      padding: 3.125vw 2.96875vw;
      border: 1px solid var(--Seconday-color, #b3b9bd);
      background: var(--Button-Text-color, #fff);
    }

    .set-password-text {
      color: var(--Text-Color, #333);
      font-family: Manrope;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 1.0417vw;
    }
  }
}

.password-policy-div {
  border-radius: 5px;
  background: #f5f5f5;
  display: flex;
  width: 100%;
  padding: 1.0417vw 1.25vw;
  align-items: center;
  gap: 8px;
  font-family: Manrope;
  margin: 0 0 3.5185185185185186vh 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;

  ul {
    padding: 0 0 0 0.834vw;
    margin: 0;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1450px) {
  .setpassword-silderimage {
    width: 45vw;
  }

  .set-password-form {
    padding: 2vw !important;
  }

  .set-password-text {
    font-size: 28px !important;
  }

  .slider-header-text {
    font-size: 28px !important;
  }

  .slider-subheader-text {
    font-size: 18px !important;
  }
}

/* .ant-input-wrapper .ant-input-group {
  display: flex;
  align-items: center;
}


.ant-input-group-addon {
  } */
/* .ant-input-wrapper .ant-input-group .css-dev-only-do-not-override-1gwfwyx {
  display: flex !important;
  justify-content: center;
  align-items: center !important;
} */
@media screen and (max-height: 900px) {
  .signup-input-fields {
    height: 42px;
  }

  .signup-phone-field {
    height: 42px;
  }
}

.ant-notification-notice {
  border-radius: 10px;
}

@media screen and (max-width: 1200px) {
  .slideshow-col {
    display: none;
  }
}
