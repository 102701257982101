.login-page {
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
}

.logo-desc-container {
    .logo-container-div {
        display: flex !important;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* gap: 1.875vw; */
    }

    .ivory-login-bg {
        height: 74vh;
        padding: 0;
        margin-top: 4vh;
    }

    .ivory-description {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 44.584vw;
        /* height: 5.42vw; */

        .description-header {
            color: var(--Text-Color, #333);
            text-align: center;
            font-family: Manrope;
            font-size: 32px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .description-content {
            color: var(--Seconday-color, #B3B9BD);
            text-align: center;
            font-family: Manrope;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 123%;
            letter-spacing: 0.043px;
        }
    }

    .carousel-item {
        position: unset;
    }

    .carousel-caption {
        position: unset;
        padding-top: 0;
        padding-bottom: 3vw;
    }

    .carousel-indicators [data-coreui-target] {
        width: 1.35417vw;
        background-color: #AEAEAE;
        margin-bottom: 0;
    }

    .carousel-indicators .active {
        width: 1.35417vw;
        background-color: #006FE6;
    }
}

.login-form-container {
    padding: 0 4.6875vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1.563vw;

    .ivory-logo-img {
        height: 4.837685185185185vh;
        flex-shrink: 0;
    }

    .login-form {
        width: 30.52vw;
        padding: 3.125vw 2.96875vw;
        border-radius: 0.26vw;
        border: 1px solid var(--Seconday-color, #B3B9BD);
        background: var(--Button-Text-color, #FFF);
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
    }

    .login-text {
        color: var(--Text-Color, #333);
        font-family: Manrope;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 1.0417vw;
    }

    .login-form-field {
        display: flex;
        flex-direction: column;
        width: 24.5834vw;
        /* height: 11vh; */
        /* gap: 0.252vw; */
        margin-bottom: 0.625vw;

        .login-form-input {
            display: inline-flex;
            padding: 0.7298vw 0.625vw;
            align-items: center;
            border-radius: 0.26vw;
            border: 1px solid var(--Seconday-color, #B3B9BD);
            background: #F3F9FD;
            color: var(--Text-Color, #333);
            font-family: Manrope;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }

    .email-text,
    .password-text {
        color: #A3A3A3;
        font-family: Manrope;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 0.26vw;
    }

    .error-message {
        height: 2.2845vh;
    }
}

.ant-input {
    line-height: 0;
}

.ant-form-item {
    margin: 0;
}

.login-form-checkbox {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.remeber-me-checkbox {
    color: #000;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;

    .ant-checkbox .ant-checkbox-inner {
        width: 24px !important;
        height: 24px !important;
    }

    .ant-checkbox .ant-checkbox-inner:after {
        top: 40%;
        width: 0.364584vw;
        height: 0.78125vw;
    }
}

.forgot-password-span-tag {
    color: var(--Primary-color, #006FE6);
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
}

.login-button {
    display: flex;
    width: 24.5834vw;
    /* height: 58px; */
    padding: 1.459vw 1.3542vw;
    margin-top: 5.3vh;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 0.26vw;
    background: var(--Primary-color, #006FE6);
    color: var(--Button-Text-color, #FFF);
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.ant-divider-horizontal.ant-divider-with-text {
    color: var(--Seconday-color, #B3B9BD);
    font-family: Manrope;
    font-size: 16px;
    margin: 1.35417vw 0;
}

.ant-divider-horizontal.ant-divider-with-text::before,
.ant-divider-horizontal.ant-divider-with-text::after {
    border-block-start: 1px solid #B3B9BD;
}


.buttons-container {
    /* display: flex;
    flex-direction: column;
    gap: 2.4vh; */

    .google-button {
        display: flex;
        width: 24.5834vw;
        /* height: 58px; */
        padding: 1.459vw 1.3542vw;
        justify-content: center;
        align-items: center;
        gap: 8px;

        border-radius: 0.26vw;
        border: 1px solid var(--Seconday-color, #B3B9BD);
        color: var(--Text-Color, #333);
        font-family: Manrope;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .register-clinic-button {
        display: flex;
        width: 24.5834vw;
        /* height: 58px; */
        padding: 1.459vw 1.3542vw;
        justify-content: center;
        align-items: center;
        gap: 8px;

        border-radius: 0.26vw;
        border: 1px solid var(--Primary-color, #006FE6);
        background: var(--Selected, #E0F4FF);
        color: var(--Primary-color, #006FE6);
        font-family: Manrope;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}

@media screen and (max-width: 820px) {
    .ivory-login-bg {
        width: 93% !important;
        height: fit-content !important;
    }

    .ivory-description {
        width: 88% !important;
    }

    .login-form-container {
        padding: 12vh 0 10vh !important;
    }

    .login-form {
        width: 57% !important;
    }

    .login-form-field {
        width: 50vw !important;
    }

    .login-form-input {
        padding: 1.6vw !important;
    }

    .remeber-me-checkbox {
        .ant-checkbox-inner {
            width: 2.4vw !important;
            height: 2.4vw !important;
        }

        .ant-checkbox .ant-checkbox-inner:after {
            width: 0.7vw !important;
            height: 1.5vw !important;
        }
    }
}

@media screen and (max-width: 991px) and (min-height: 1360px) {
    .login-form-container {
        height: 67vh;
    }
}

@media screen and (max-width: 991px) {
    .logo-desc-container {
        display: none;
    }

    .login-form-container {
        padding: 0 !important;
    }

    .login-form {
        width: 55vw !important;
        border: none !important;
    }

    .login-form-field,
    .login-button,
    .register-clinic-button {
        width: 50vw !important;
    }

    .login-form-input {
        padding: 1.4vw !important;
    }

    .login-button,
    .register-clinic-button {
        padding: 2.9vw 1.3542vw !important;
    }

    .remeber-me-checkbox {
        .ant-checkbox .ant-checkbox-inner {
            width: 2.25vw !important;
            height: 2.25vw !important;
        }

        .ant-checkbox .ant-checkbox-inner:after {
            width: 0.7vw !important;
            height: 1.4vw !important;
        }
    }
}

@media screen and (max-width: 820px) and (min-height: 1000px) {
    .login-form-container {
        height: 78vh !important;
    }
}

@media screen and (min-width: 2000px) {
    .logo-desc-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (min-width: 1450px) and (max-width: 1750px) {
    .ivory-login-bg {
        height: 69vh !important;
        padding: 0 !important;
    }
}

@media screen and (min-width: 2000px) and (min-height: 1200px) {
    .login-form-container {
        height: unset !important;
        padding: 0 3vw 0 0 !important;
        justify-content: center !important;
    }

    .login-form {
        width: 23vw !important;
        padding: 2vw !important;
    }

    .login-form-field,
    .login-button,
    .register-clinic-button {
        width: 19vw !important;
    }

    .login-button,
    .register-clinic-button {
        padding: 1.1vw !important;
    }
}


@media screen and (min-width: 992px) and (max-width: 1060px) {
    .ivory-login-bg {
        height: 60vh !important;
        padding: 0 !important;
    }

    .login-form-container {
        padding: 0;
    }

    .login-form {
        width: 38vw !important;
        padding: 3.5vw 2vw !important;
    }

    .login-form-field {
        width: 34vw !important;
    }

    .login-button,
    .register-clinic-button {
        width: 34vw !important;
        padding: 2vw !important;
    }

    .remeber-me-checkbox {
        .ant-checkbox .ant-checkbox-inner {
            width: 2vw !important;
            height: 2vw !important;
        }

        .ant-checkbox .ant-checkbox-inner:after {
            top: 43% !important;
            width: 0.7vw !important;
            height: 1.3vw !important;
        }
    }
}

@media screen and (width: 1024px) and (height: 1366px) {
    .login-page {
        justify-content: center;
        align-items: flex-start;
    }
}

@media screen and (min-width: 1061px) and (max-width: 1199px) {
    .ivory-login-bg {
        height: 66vh !important;
        padding: 0 !important;
    }

    .ivory-description {
        .description-header {
            font-size: 26px !important;
        }

        .description-content {
            font-size: 18px !important;
        }
    }

    .login-form {
        width: 33vw !important;
        padding: 2vw !important;
    }

    .login-form-field {
        width: 29vw !important;
    }

    .login-button,
    .register-clinic-button {
        width: 29vw !important;
        padding: 1.9vw !important;
    }

    .remeber-me-checkbox {
        .ant-checkbox .ant-checkbox-inner {
            width: 1.8vw !important;
            height: 1.8vw !important;
        }

        .ant-checkbox .ant-checkbox-inner:after {
            top: 45% !important;
            width: 0.6vw !important;
            height: 1.1vw !important;
        }
    }
}

@media screen and (min-width: 1200px) and (max-width: 1450px) {
    .ivory-login-bg {
        padding: 0 !important;
        height: 66vh !important;
    }

    .ivory-description {
        .description-header {
            font-size: 28px !important;
        }

        .description-content {
            font-size: 20px !important;
        }
    }

    .login-form-container {
        padding: 0 3.5vw;

        .login-text {
            font-size: 28px !important;
        }
    }

    .login-form {
        width: 32vw !important;
        padding: 2vw !important;

        .login-form-field,
        .login-button,
        .register-clinic-button {
            width: 26vw;
        }

        .login-button,
        .register-clinic-button {
            padding: 1.8vw !important;
        }

        .login-button {
            margin-top: 4.8vh !important;
        }

        .ant-divider-horizontal.ant-divider-with-text {
            margin: 1vw 0 !important;
        }

        .remeber-me-checkbox {
            .ant-checkbox .ant-checkbox-inner {
                width: 1.7vw !important;
                height: 1.7vw !important;
            }

            .ant-checkbox .ant-checkbox-inner:after {
                top: 45% !important;
                width: 0.5vw !important;
                height: 1vw !important;
            }
        }
    }
}

/* @media screen and (min-width: 1321px) and (max-width: 1520px) {
    .ivory-login-bg {
        width: 85% !important;
    }

    .login-form-container {
        padding: 10vh 3.5vw 10vh 0;
    }

    .login-form {
        width: 33vw !important;
        padding: 2.5vw 2.5vw !important;

        .login-form-field,
        .login-button,
        .register-clinic-button {
            width: 28vw;
        }

        .login-button,
        .register-clinic-button {
            padding: 1.7vw;
        }

        .remeber-me-checkbox {
            .ant-checkbox .ant-checkbox-inner {
                width: 1.55vw !important;
                height: 1.55vw !important;
            }

            .ant-checkbox .ant-checkbox-inner:after {
                width: 0.5vw !important;
                height: 1vw !important;
            }
        }
    }
} */