.settings-menu-main-div {
  height: calc(100% - 52px);
  padding: 0.52vw 1.5625vw 0;
  overflow: auto;

  .ant-tabs.ant-tabs-top,
  .ant-tabs-content-holder {
    height: 100%;
  }

  .ant-tabs-top>.ant-tabs-nav {
    margin: 0 0 0px 0;
  }

  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--Primary-Color, #006fe6);
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: var(--font-size-h3, 16px);
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    /* 112.5% */
    letter-spacing: 0.53px;
    text-shadow: none;
  }

  .ant-tabs .ant-tabs-ink-bar {
    position: absolute;
    background: #006fe6;
    pointer-events: none;
  }

  .ant-tabs .ant-tabs-tab+.ant-tabs-tab {
    margin: 0 0 0 0px;

    padding: 19px 16px;
    color: var(--Secondary-Text-Color, #979797);
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 112.5% */
    letter-spacing: 0.53px;
  }

  .ant-tabs .ant-tabs-tab {
    padding: 19px 16px;

    color: var(--Secondary-Text-Color, #979797);
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 112.5% */
    letter-spacing: 0.53px;
  }
}

.general-tab-main-div,
.patient-details-tab-main-div {
  height: 100%;

  .ant-tabs-card.ant-tabs-left>.ant-tabs-nav .ant-tabs-tab {
    background: transparent;
    border: none;
    border-bottom: 1px solid var(--Line, #d9d9d9);
    border-radius: 0px;
    width: 306px;
    padding: 0 32px;
    height: 56px;
  }

  .ant-tabs.ant-tabs-left.ant-tabs-card {
    height: 100%;
  }

  .ant-tabs .ant-tabs-content {
    height: 98% !important;
  }

  /* .ant-tabs-content-holder {
    height: 65vh;
  } */

  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--shades-black, #000);
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    /* 112.5% */
    letter-spacing: 0.53px;
  }

  .ant-tabs .ant-tabs-tab .ant-tabs-tab-btn {
    color: #333;
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 112.5% */
    letter-spacing: 0.53px;
  }

  .ant-tabs-nav-list {
    padding: 12px 0 0 0;
  }

  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    width: 100%;
  }
}

.date-time-tab-main-div {
  .save-btn {
    height: 40px;
    padding: 4px var(--font-size-h2, 20px);
    border-radius: 5px;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 600;
  }

  .cancel-btn {
    display: flex;
    height: 40px;
    padding: 4px var(--font-size-h2, 20px);
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 5px;
    border: 1px solid var(--Primary-Color, #ec7063);
    background: var(--White, #fff);
    color: var(--Primary-Color, #ec7063);
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 15.247px;
    letter-spacing: 0.53px;
  }
}

.clinic-profile-main-div {
  height: 100%;
  overflow: auto;
  padding: 1.25vw;

  .save-btn {
    display: flex;
    height: 40px;
    padding: 4px var(--font-size-h2, 20px);
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 5px;
    border: 1px solid var(--Primary-Color, #006fe6);
    background: var(--White, #fff);
    color: var(--Primary-Color, #006fe6);
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 15.247px;
    /* 95.291% */
    letter-spacing: 0.53px;
  }

  .profile-pic-div {
    margin: 0 0 48px 0;
    display: flex;
    width: 245px;
    padding: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: var(--BG, #f3f4f8);
  }

  .language-date-time-section {
    display: flex;
    flex-direction: column;
    gap: 24px 0;
  }

  .choose-btn {
    margin: 8px 0 0 0;
    display: flex;
    height: 43px;
    max-width: 100px;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 5px;
    border: 1px solid var(--Primary-Color, #006fe6);
    background: var(--White, #fff);
    color: var(--Primary-Color, #006fe6);
    text-align: center;
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 15.247px;
    /* 95.291% */
    letter-spacing: 0.53px;
  }

  .checkbox {
    color: var(--Secondary-Text-Color, #979797);
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .form-footer {
    gap: 12px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    padding: 62px 0 0 0;
  }

  .cancel-btn {
    display: flex;
    height: 40px;
    padding: 4px var(--font-size-h2, 20px);
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 5px;
    border: 1px solid var(--Primary-Color, #ec7063);
    background: var(--White, #fff);
    color: var(--Primary-Color, #ec7063);
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 15.247px;
    /* 95.291% */
    letter-spacing: 0.53px;
  }
}

.table-header-names {
  color: rgba(135, 135, 135, 0.85);
  font-feature-settings: "liga" off, "clig" off;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 128.571% */
  letter-spacing: 0.53px;
  text-transform: uppercase;
}

.access-control-main-div {

  .ant-table-tbody>tr>th,
  .ant-table-tbody>tr>td {
    color: #333;
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 15.247px;
    /* 95.291% */
    letter-spacing: 0.53px;
    text-transform: uppercase;
  }
}

.table-col-names {
  color: #333;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.247px;
  /* 95.291% */
  letter-spacing: 0.53px;
  text-transform: uppercase;
}

.table-content-input {
  border-radius: 3px;
  border: 1px solid #dadada;
  background: #fff;
  display: flex;
  height: 46px;
  padding: 12px var(--font-size-h3, 16px);
  align-items: center;
  gap: 10px;
  align-self: stretch;
  color: var(--Text-Color, #333) !important;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.date-time-tab-main-div {
  padding: 36px 24px;

  .time-select {
    display: flex;
    width: 84px;
    height: 46px;
    align-items: center;
    border-radius: 3px;
    border: 1px solid #dadada;
    background: #fff;
  }
}

.input-header {
  color: #757575;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.input {
  color: var(--Text-Color, #333);
  font-family: Manrope;
  font-size: 16px;
  /* width: 10.885416666666666vw; */
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 3px;
  border: 1px solid #dadada;
  background: #fff;
  display: flex;
  min-height: 46px;
  padding: 12px var(--font-size-h3, 16px);
  align-items: center;
  gap: 10px;
  align-self: stretch;
  margin: 8px 0 0 0;
}

.select {
  font-family: Manrope;
  color: var(--Text-Color, #333) !important;
  font-size: 16px;
  /* width: 209px; */
  font-weight: 600 !important;
  border-radius: 3px;
  border: 1px solid #dadada;
  background: #fff;
  height: 46px;
  margin: 8px 0 0 0;
}

.language-date-time-text {
  padding: 40px 0 0 0;
  color: var(--Primary-Text-Color, #333);
  font-feature-settings: "liga" off, "clig" off;
  font-family: Manrope;
  font-size: var(--font-size-h3, 16px);
  font-style: normal;
  font-weight: 400;
  line-height: 15.247px;
  /* 95.291% */
  letter-spacing: 0.53px;
}

.clinic-profile-main-div,
.date-time-tab-main-div,
.holiday-drawer-main-div,
.documents-drawer-main-div {

  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    color: var(--Primary-Text-Color, #333);
    font-weight: 600;
  }
}

.documents-drawer-main-div {
  overflow: auto;
}

.search-input {
  width: 280px;
  height: 35px;
  gap: 10px;
  border-radius: 5px;
  border: var(--Number, 1px) solid var(--Line, #bababa);
  background: var(--White, #fff);
}

.tab-extra-text {
  color: #333;
  gap: 6px;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.53px;
  padding: 10px;
  cursor: pointer;
}

.tab-extra-text:hover {
  border-radius: 5px;
  background-color: #f3f4f8;
  transition: 0.3s ease;
}

.side-tabs-label {
  font-family: Manrope;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.5299999713897705px;
  text-align: left;
}

.add-holiday-header {
  color: var(--Text-Color, #333);
  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.access-control-main-div {
  padding: 12px 0 0 0;
}

.roles-main-div {
  padding: 12px 0;

  .role-name-text {
    color: var(--Primary-Color, #006fe6);
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 15.247px;
    /* 95.291% */
    letter-spacing: 0.53px;
    cursor: pointer;
  }
}

.select-role-dropdown {
  width: 100%;
  height: 46px;
  border-radius: 3px;
  border: 1px solid #dadada;
  background: #fff;
  flex-shrink: 0;
}

.select-role-text {
  color: var(--Seconday-color, #b3b9bd);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.documents-main-div .tooltip-container {
  padding: 0.83vw 1.5625vw 1.5625vw;

  .close-button {
    display: flex;
    justify-content: end;
  }

  .close-icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .data-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.83vw;

    color: #878787;
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 15.247px;
    letter-spacing: 0.53px;
    text-transform: uppercase;

    .notes-data-header-row {
      display: flex;
      flex-direction: column;
      gap: 1.0417vw;

      .app-date {
        color: #333;
        font-size: 16px;
      }
    }

    .note-divider {
      margin: 0;
      background: #d9d9d9;
    }

    .notes-data-row {
      display: flex;
      flex-direction: column;
      gap: 0.63vw;
      flex: 1;

      .date {
        color: #333;
        font-size: 16px;
      }
    }

    .doctor-name {
      color: #006fe6;
      font-size: 16px;
      font-weight: 600;
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-decoration-skip-ink: none;
      text-decoration-thickness: auto;
      text-underline-offset: auto;
      text-underline-position: from-font;
    }

    .app-notes {
      display: flex;
      flex-direction: column;
      gap: 1.5265vw;

      color: #333;
      font-feature-settings: "liga" off, "clig" off;
      font-family: Manrope;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22.2px;
      letter-spacing: 0.53px;
      text-transform: capitalize;

      span {
        font-weight: 600;
      }

      .note-desc {
        display: flex;
        flex-direction: column;
      }
    }

    .note-details {
      display: flex !important;
      flex-direction: column !important;

      color: #333;
      font-size: 16px;
      line-height: 22.2px;
    }

    .title {
      color: #333;
      font-size: 20px;
      font-weight: 600;
    }
  }
}