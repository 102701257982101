/* @import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700&display=swap"); */
@import "@coreui/coreui/dist/css/coreui.min.css";

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Manrope, "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif, Manrope; */
  font-family: Manrope, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* 
::-webkit-scrollbar {
  width: 0;
  height: 0;
} */
::-webkit-scrollbar {
  width: 1px;
  height: 1px;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #adadad;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.d-flex {
  display: flex;
}

.jc-center {
  justify-content: center;
}

.jc-start {
  justify-content: flex-start;
}

.jc-end {
  justify-content: flex-end;
}

.jc-around {
  justify-content: space-around;
}

.jc-between {
  justify-content: space-between;
}

.jc-evenly {
  justify-content: space-evenly;
}

.jc-right {
  justify-content: right;
}

.ai-center {
  align-items: center;
}

.ai-end {
  align-items: flex-end;
}

.ai-start {
  align-items: flex-start;
}

.flex-col {
  flex-direction: column;
}

::-webkit-scrollbar {
  width: 0.5vw;
  height: 0;
}

::-webkit-scrollbar-thumb {
  background-color: #b3b9bd;
}
